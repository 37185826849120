.panel-wrapper {
    .panel-intro {
        background: rgb(83, 96, 126);
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        .site-logo {
            width: 42%;
            margin: 0 auto;
        }

        .redirect-home {
            color: $white;
            font-size: 16px;
            line-height: 0px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 10px;
            img {
                margin-right: 3px;
                width: 15px;
                height: 15px;
            }
        }
        .author-name {
            font-size: 30px;
            line-height: 30px;
            font-weight: 600;
            color: $primary;
            color: $white;
            margin-top: 8px;
        }
    }

    .panel-content {
        form{
            max-width: 600px;
        }
    }
}

.widget-item {
    margin-bottom: 50px;
    @include ipad {
        margin-bottom: 30px;
    }
    .widget-title {
        font-size: 18px;
        color: $primary;
        line-height: 25px;
        text-transform: uppercase;
        font-family: OpenSans-Semibold;
        padding-bottom: 4px;
        border-bottom: 1px solid #f5f5f5;
        @include ipad {
            font-size: 15px;
            line-height: 23px;
            h6 {
                margin-bottom: 0px;
            }
        }
    }
    .widget-content {
        .inline-action {
            cursor: pointer;
            h6 {
                margin-bottom: 0px;
                color: #999797;
                font-family: OpenSans-Semibold;
                font-size: 16px;
                @include ipad {
                    font-size: 15px;
                    line-height: 23px;
                }
            }

            svg {
                fill: #999797;
            }
        }
        .plan-details {
            display: flex;
            justify-content: space-between;
            align-items: center;

            @include ipad {
                display: block;
            }
            .plan-info {
                &.order-modal {
                    h5 {
                        font-size: 18px;
                        line-height: 28px;
                        @include ipad {
                            font-size: 16px;
                            line-height: 23px;
                        }
                    }
                }
                h5 {
                    font-size: 22px;
                    color: $primary;
                    line-height: 38px;
                    font-family: OpenSans-Semibold;
                    margin-bottom: 5px;
                    margin-top: 10px;
                    text-transform: capitalize;
                    @include ipad {
                        font-size: 20px;
                        line-height: 32px;
                    }
                }

                h4 {
                    font-size: 30px;
                    color: $primary;
                    line-height: 40px;
                    font-family: OpenSans-Bold;
                    @include ipad {
                        font-size: 25px;
                        line-height: 35px;
                    }
                }
                .renew-text {
                    font-size: 15px;
                    color: $primary;
                    line-height: 25px;
                    font-family: OpenSans-Light;
                    @include ipad {
                        font-size: 14px;
                        line-height: 23px;
                    }
                }
            }

            .action-btn {
                @include ipad {
                    margin-bottom: 25px;
                    margin-top: 10px;
                    display: inline-block;
                    width: 50%;
                }

                &.change-status {
                    min-width: 100px;
                    margin-left: 20px;
                    @include ipad {
                        margin-left: 0px;
                    }
                    span {
                        text-align: center;
                        margin-bottom: 6px;
                        font-weight: 600;
                        display: inline-block;
                        color: $primary;
                    }
                    .form-select {
                        outline: none;
                        padding: 3px 12px;
                        cursor: pointer;
                        &:focus {
                            outline: none;
                            box-shadow: none;
                        }
                    }
                }
                button {
                    outline: none;
                    border: none;
                    width: 150px;
                    height: 40px;
                    text-align: center;
                    background: #d71833;
                    font-size: 15px;
                    border-radius: 3px;
                    color: $white;
                    font-family: OpenSans-Semibold;

                    &:disabled {
                        background: #dedede;
                        color: $primary;
                        cursor: not-allowed;
                    }

                    @include ipad {
                        width: 100%;
                    }

                    &.cancel-btn {
                        background-color: $white;
                        color: $primary;
                        border: 1px solid #f4f4f4;
                        margin-top: 12px;
                    }
                }
            }
        }

        .card-body {
            min-height: 145px;
            &.order-modal {
                min-height: 130px;
                .card-title {
                    font-size: 15px;
                }
                .card-text {
                    font-size: 13px;
                }
            }
        }

        .card-footer {
            &.order-modal {
                font-size: 14px;
            }
        }
        &.payment-method {
            &.table-responsive {
                overflow-x: auto;
                overflow-y: hidden;
            }
            .table tr {
                vertical-align: middle;
            }
            .table > :not(caption) > * > * {
                padding: 0.8rem 0.5rem;
                border-bottom-width: 0px;
            }
        }
    }
}

.back-step {
    color: $primary;
    font-family: OpenSans-Semibold;
    font-size: 15px;
    cursor: pointer;
    line-height: 0;
    margin-bottom: 10px;
}

.step-title {
    color: $primary;
    font-family: OpenSans-Bold;
    font-size: 30px;
    line-height: 40px;
    margin: 20px 0px;
    text-transform: capitalize;
}

.price-item {
    border: 1px solid #f5f5f5;
    border-radius: 5px;
    .title {
        font-size: 17px;
        color: $primary;
        font-weight: 600;
    }
    .text {
        font-size: 16px;
        color: $black;
    }
    .price {
        .number {
            display: block;
            font-size: 35px;
            line-height: 42px;
            color: $primary;
            font-family: OpenSans-Bold;
        }
        .text {
            font-size: 15px;
            color: $black;
            margin-top: 5px;
        }
    }

    button {
        outline: none;
        border: 1px solid #f1f1f1;
        width: 100%;
        height: 35px;
        text-align: center;
        background: $white;
        font-size: 14px;
        border-radius: 3px;
        color: $primary;
        font-family: OpenSans-Semibold;
    }
}

.total-amount {
    h6,
    span {
        color: $primary;
        font-family: OpenSans-Bold;
        font-size: 25px;
        line-height: 35px;
        text-transform: capitalize;
    }
}

.logout-action {
    a {
        color: $primary;
        font-family: OpenSans-Semibold;
    }
}

// .success-process {
//     height: 60vh;
//     overflow-y: auto;
// }

.download-btn {
    &:hover {
        color: $white;
    }
}
