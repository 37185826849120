// SIGN-UP

.full_height_section {
    padding-top: 175px;
    background-size: cover;
    .container {
        z-index: 2;
    }
}

.auth_section {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
    padding-bottom: 60px;

    form {
        width: 100%;
        fieldset {
            width: 100%;
            padding: 0;
            border: none;
            margin-bottom: 25px;
        }
        .input_label {
            font-size: 14px;
            line-height: 17px;
            color: $primary;
            margin-bottom: 2px;
            font-weight: 600;
        }
        input {
            width: 100%;
            background-color: rgba(226, 227, 228, 0.2);
            height: 38px;
            padding: 0 10px;
            font-size: 14px;
            line-height: 17px;
            color: $primary;
            border: none;
            &::placeholder {
                color: $primary;
            }
        }
        button {
            margin-bottom: 18px;
        }
    }

    .plant {
        top: 25px;
        right: -10px;
        z-index: 1;
    }
    @include tablet {
        padding-top: 100px;
        padding-bottom: 30px;
        .plant {
            right: 0;
            width: 150px;
        }
    }
}

.auth_inner {
    .small_logo {
        width: 29px;
        margin-bottom: 16px;
    }
    h3,
    .h3 {
        margin-bottom: 30px;
        color: $primary;
    }

    form {
        .input_label {
            color: $primary;
        }
    }

    .primary_btn {
        background: $primary;
        color: $white;
    }
    .form_bottom_text {
        font-size: 15px;
        line-height: 18px;
        color: $primary;
        margin-bottom: 0;
        a {
            color: $primary;
            text-decoration: none;
        }
    }
}
