$owl-image-path: "~owl.carousel/src/img/";
@import "~owl.carousel/src/scss/owl.carousel";
@import "~owl.carousel/src/scss/owl.theme.default";
/* Colors */
$main-fonts-color: #000000;
$red-color: #d71833;

/* Sizes */
$size-4k: "2650px";
$size-xlg: "1800px";
$size-slg: "1200px";
$size-lg: "1200px";
$size-md: "991px";
$size-sm: "767px";
$size-xs: "460px";

/* Fonts */
$main-font: "OpenSans-Light";

@font-face {
    font-family: OpenSans-Light;
    src: url(../fonts/OpenSans-Light.ttf);
}
@font-face {
    font-family: OpenSans-Semibold;
    src: url(../fonts/OpenSans-Semibold.ttf);
}
@font-face {
    font-family: OpenSans-Bold;
    src: url(../fonts/OpenSans-Bold.ttf);
}
@font-face {
    font-family: PlayfairDisplay-Regular;
    src: url(../fonts/PlayfairDisplay-Regular.ttf);
}
@font-face {
    font-family: PlayfairDisplay-Bold;
    src: url(../fonts/PlayfairDisplay-Bold.ttf);
}
@font-face {
    font-family: MyriadPro-Regular;
    src: url(../fonts/MyriadPro-Regular.ttf);
}

@media (min-width: $size-4k) {
    .container {
        max-width: 1400px;
    }
}
.container-fluid {
    @media (max-width: $size-md) {
        padding: 0px 60px;
    }
    @media (max-width: $size-sm) {
        padding: 0px 20px;
    }
}

html {
    overflow-x: hidden;
}
body {
    color: $main-fonts-color;
    font-family: $main-font;
    background: #ffffff;
    overflow: hidden;

    line-height: 1;
    letter-spacing: normal;
    font-stretch: normal;

    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    ul {
        padding: 0px;
        margin: 0px;

        li {
            list-style-type: none;
            display: inline-block;
        }
    }

    a {
        color: $main-fonts-color;
        text-decoration: none;
    }

    a:hover {
        color: inherit;
        text-decoration: none;
    }

    .link {
        cursor: pointer;
    }

    input:focus,
    a:active,
    a:focus,
    select:focus,
    textarea:focus,
    button:focus,
    *:focus,
    input,
    textarea,
    a {
        outline: none !important;
    }

    img {
        border: 0;
        max-width: 100%;
    }
    .no-padding {
        padding: 0px;
    }
    .z-index-2 {
        z-index: 2;
    }

    .owl-item {
        display: inline-block;
    }
    .owl-stage-outer {
        overflow: hidden;
    }
    .disabled {
        display: none;
    }
}
.modal-open {
    padding-right: 0px !important;
}
// Header
header {
    position: relative;
    z-index: 3;
    .top {
        background: $red-color;
        font-size: 18px;
        font-family: MyriadPro-Regular;
        color: white;
        padding: 20px 0px;
    }

    .logo {
        display: inline-block;
        vertical-align: middle;
        img {
            max-width: 250px;
            width: 250px;

            @media (max-width: $size-sm) {
                max-width: 200px;
                width: 200px;
            }
        }
    }

    .menu {
        float: right;
        display: inline-block;
        vertical-align: middle;
        padding: 0px 100px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);

        @media (max-width: $size-md) {
            display: none;
        }

        ul {
            li {
                list-style-type: none;
                display: inline-block;
                color: white;
                margin-right: 30px;

                a {
                    text-decoration: none;
                    color: white;
                    text-transform: uppercase;
                    font-family: MyriadPro-Regular;
                    font-size: 18px;
                    cursor: pointer;
                }
            }
            li:last-child {
                margin-right: 0px;
            }
        }
    }
    .mobile-bar {
        float: right;
        display: none;
        img {
            width: 26px;
        }
        @media (max-width: $size-md) {
            display: inline-block;
        }
    }
}

// Mobile menu
.mobile-menu {
    width: 100%;
    height: 100%;
    display: none;
    position: fixed;
    top: 0px;
    background: white;
    z-index: 12;
    padding: 28px;

    .top {
        .logo {
            display: inline-block;
            width: 50%;
        }

        .icon {
            display: inline-block;
            width: 45%;
            float: right;
            text-align: right;
            color: $main-fonts-color;
            font-size: 24px;
            margin-top: -5px;
            cursor: pointer;
        }
    }

    .list {
        display: flex;
        align-items: center;
        //justify-content: center;
        position: relative;
        height: 100%;

        ul {
            text-align: left;
            margin: auto;

            li {
                display: block;
                font-size: 22px;
                margin-bottom: 25px;
                color: $main-fonts-color;
                padding-bottom: 5px;

                a {
                    color: $main-fonts-color;
                    text-transform: uppercase;
                    text-decoration: none;
                }
            }
        }
    }
}

.home-page {
    .container,
    .row {
        z-index: 3;
        position: relative;
    }
    .main-title {
        font-family: PlayfairDisplay-Regular;
        font-size: 40px;
        margin-bottom: 20px;
    }
    .main-text {
        p,
        ul {
            font-family: $main-font;
            font-size: 16px;
            line-height: 28px;
            @include ipad {
                font-size: 15px;
            }
        }
        .bold {
            font-family: OpenSans-Bold;
        }
    }
    .main-button {
        background: $red-color;
        color: white;
        font-size: 14px;
        font-family: OpenSans-Semibold;
        height: 47px;
        display: block;
        width: 210px;
        text-transform: uppercase;
        border-radius: 25px;
        text-align: center;
        padding: 16px;
    }

    .main-section {
        position: relative;
        background: url("../images/top-header-image.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        color: white;

        .bg {
            background: black;
            opacity: 0.5;
            z-index: 1;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0px;
        }

        .container,
        .row {
            height: 100%;
            z-index: 3;
            position: relative;
        }

        .left-text {
            padding-top: 80px;

            .title {
                font-family: PlayfairDisplay-Regular;
                font-size: 70px;
                margin-bottom: 10px;
                @media (max-width: $size-md) {
                    font-size: 50px;
                }
                @media (max-width: $size-sm) {
                    font-size: 42px;
                }
            }
            .subtitle {
                font-family: OpenSans-Light;
                font-size: 18px;
                line-height: 29px;
                margin-bottom: 10px;
            }
            .info {
                ul {
                    li {
                        font-family: OpenSans-Light;
                        font-size: 18px;
                        display: block;
                        margin-bottom: 15px;
                        line-height: 29px;
                        white-space: nowrap;
                        .bold {
                            font-family: OpenSans-Semibold;
                        }
                        svg {
                            width: 13px;
                            display: inline-block;

                            .cls-1 {
                                stroke: #eccb41 !important;
                            }
                        }
                        .right-text {
                            display: inline-block;
                            white-space: normal;
                            vertical-align: text-top;
                        }
                    }
                }
            }

            .review-block {
                background: linear-gradient(87deg, #ebc440 0%, #ebc440 20%, #d71833 100%);
                font-size: 0px;
                padding: 30px 40px;
                border-radius: 20px 20px 20px 20px;
                margin-left: -20px;
                max-width: 80%;
                position: relative;
                margin-top: 50px;
                margin-bottom: 40px;

                @media (max-width: $size-lg) {
                    margin-left: 0px;
                    max-width: 100%;
                }
                @media (max-width: $size-md) {
                    margin-left: 0px;
                    max-width: 100%;
                    padding: 20px 20px;
                }

                .lines {
                    img {
                        width: 200px;
                    }
                }
                .icon {
                    margin-bottom: 10px;
                    svg {
                        width: 24px;
                    }

                    .cls-1 {
                        stroke: white !important;
                    }
                }
                .product {
                    position: absolute;
                    left: -200px;
                    top: -61px;

                    @media (min-width: $size-4k) {
                        left: -232px;
                        top: -49px;
                    }
                    @media (max-width: $size-lg) {
                        top: -37px;
                    }

                    img {
                        height: 400px;

                        @media (min-width: $size-4k) {
                            height: 450px;
                        }
                    }

                    @media (max-width: $size-md) {
                        display: none;
                    }
                }
                .text {
                    display: inline-block;
                    width: 70%;
                    color: white;
                    vertical-align: middle;
                    padding-right: 30px;

                    @media (max-width: $size-sm) {
                        width: 100%;
                    }

                    .info {
                        font-size: 18px;
                        font-family: PlayfairDisplay-Regular;
                        margin-bottom: 25px;
                    }
                    .prof {
                        font-family: OpenSans-Bold;
                        font-size: 14px;
                        margin-bottom: 5px;
                    }
                    .bottom {
                        font-family: MyriadPro-Regular;
                        font-size: 14px;
                    }
                }
                .image {
                    display: inline-block;
                    width: 30%;
                    vertical-align: middle;
                    position: relative;
                    z-index: 2;

                    @media (max-width: $size-sm) {
                        width: 100%;
                        margin-top: 20px;
                        text-align: center;
                    }

                    img {
                        border-radius: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }

    .second-section {
        padding: 100px 0px;

        @media (max-width: $size-md) {
            padding: 30px 0px;
        }
        .image {
            @media (max-width: $size-md) {
                margin-top: 30px;
            }
        }
    }
    .functionality-section {
        padding: 84px 0px;
        background: url("../images/cycling.png");
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;

        @media (max-width: $size-md) {
            padding: 40px 0px;
        }

        .bg {
            background: black;
            opacity: 0.7;
            z-index: 1;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0px;
        }
        .text {
            color: white;
            width: 80%;

            @media (max-width: $size-md) {
                width: 100%;
            }

            .main-button {
                margin-top: 30px;
            }
        }
        .image {
            @media (max-width: $size-md) {
                margin-bottom: 30px;
            }
            img {
                max-height: 800px;
                object-fit: cover;
            }
        }
    }
    .reviews-section {
        background: rgb(235, 196, 64);
        background: linear-gradient(87deg, rgba(235, 196, 64, 1) 0%, rgba(235, 196, 64, 1) 20%, rgba(215, 24, 51, 1) 100%);
        padding: 30px 0px;

        .owl-dots {
            text-align: center;
            .owl-dot {
                width: 20px;
                height: 20px;
                border-radius: 100%;
                background: white;
                border: 0px;
                margin-right: 20px;
            }
            .owl-dot.active {
                background: $red-color;
            }
        }
        .image {
            padding: 40px;
            @media (max-width: $size-md) {
                padding: 0px;
                margin-top: 20px;
                margin-bottom: 20px;
                text-align: center;
            }
            img {
                border-radius: 100%;
                object-fit: cover;
                width: 400px;
                height: 400px;
                max-width: 400px;

                @media (max-width: 1400px) {
                    width: 412px;
                    height: 412px;
                    max-width: 412px;
                }
                @media (max-width: $size-md) {
                    width: 312px;
                    height: 312px;
                    max-width: 312px;
                }
                @media (max-width: $size-xs) {
                    width: 250px;
                    height: 250px;
                    max-width: 250px;
                }
            }
        }
        .text {
            color: white;
            .icon {
                margin-bottom: 30px;
                svg {
                    width: 48px;
                }

                .cls-1 {
                    stroke: white !important;
                }
            }
            .info {
                font-size: 30px;
                font-family: PlayfairDisplay-Regular;
                line-height: 36px;
                margin-bottom: 30px;
            }
            .author {
                font-family: OpenSans-Bold;
                font-size: 18px;
                margin-bottom: 10px;
            }
            .address {
                font-family: MyriadPro-Regular;
                font-size: 18px;
            }
        }
    }
    .product-section {
        padding: 120px 0px;
        @media (max-width: $size-md) {
            padding: 40px 0px;
        }
        .title {
            margin-bottom: 60px;
        }
        .title-image {
            @media (max-width: $size-md) {
                text-align: center;
                margin-bottom: 20px;
            }

            @include ipad {
                width: 50%;
                margin: 0 auto;
            }

            img {
                @include ipad {
                    width: 100%;
                }
            }
        }
        .product {
            background: #f2f2f2;
            padding: 30px 20px;
            // padding-bottom: 70px;
            position: relative;
            height: 100%;
            max-width: 400px;
            margin: auto;

            @media (max-width: $size-md) {
                margin-bottom: 60px;
                max-width: 100%;
                height: auto;
            }

            &::after {
                content: "";
                height: 6px;
                background: #808080;
                position: absolute;
                width: 100%;
                top: -10px;
                left: 0px;
            }

            .image {
                height: 270px;
                text-align: center;
                border-bottom: 1px solid #808080;
                padding-bottom: 20px;
                margin-bottom: 20px;

                img {
                    height: 100%;
                    object-fit: cover;
                }
            }
            .name {
                font-family: PlayfairDisplay-Regular;
                font-size: 24px;
                margin-bottom: 20px;
            }
            .description {
                font-size: 18px;
                font-family: MyriadPro-Regular;
                line-height: 26px;
            }
            .button {
                position: absolute;
                cursor: pointer;
                bottom: 25px;
                left: 20px;
                color: $red-color;
                font-size: 14px;
                text-transform: uppercase;
                font-family: OpenSans-Semibold;
                svg {
                    vertical-align: inherit;
                }
            }
        }
        .product.red {
            background: #fbe7ea;
            &::after {
                background: $red-color;
            }
        }
        .product.blue {
            background: #ebf6fe;
            &::after {
                background: #3fa9f5;
            }
        }

        .images {
            margin-top: 85px;
            text-align: center;
            @media (max-width: $size-md) {
                margin-top: 0px;
            }

            .image {
                display: inline-block;
                width: 90px;
                height: 90px;
                margin-right: 30px;

                @media (max-width: $size-sm) {
                    margin-bottom: 30px;
                    margin-right: 15px;
                    width: 70px;
                    height: 70px;
                }
            }
        }
    }
    .green-section {
        padding: 84px 0px;
        background: url("../images/green-section-bg.png");
        background-repeat: no-repeat;
        background-size: cover;

        @media (max-width: $size-md) {
            padding: 30px 0px;
        }

        .text {
            color: white;
            padding-right: 60px;

            @media (max-width: $size-md) {
                padding-bottom: 75px;
                padding-right: 0px;
            }

            ul {
                li {
                    font-family: OpenSans-Light;
                    font-size: 18px;
                    display: block;
                    margin-bottom: 5px;
                    line-height: 29px;
                    white-space: nowrap;
                    svg {
                        width: 13px;
                        display: inline-block;

                        .cls-1 {
                            stroke: #eccb41 !important;
                        }
                    }
                    .right-text {
                        display: inline-block;
                        white-space: normal;
                        vertical-align: text-top;
                    }
                }
            }
        }
        .image {
            text-align: left;
            padding: 40px;
            position: relative;

            @media (max-width: $size-sm) {
                text-align: center;
            }

            .knee-image {
                max-height: 580px;
                object-fit: cover;
                position: absolute;
                left: 0px;
                right: 0px;
                margin: auto;
                top: 14%;
                @media (max-width: $size-sm) {
                    position: relative;
                    margin-bottom: 30px;
                }
            }

            .images {
                margin-left: 50px;
                position: relative;
                z-index: 2;

                @media (max-width: $size-md) {
                    margin-left: 0px;
                }
                .icon {
                    margin-bottom: 35px;
                    @media (max-width: $size-sm) {
                        text-align: center;
                    }
                    img {
                        width: 140px;
                    }
                }
                .last-icon {
                    margin-bottom: 0px;
                }
            }
        }

        .bg {
            background: black;
            opacity: 0.8;
            z-index: 1;
            position: absolute;
            width: 50%;
            height: 100%;
            top: 0px;

            @media (max-width: $size-md) {
                width: 100%;
                height: 43%;
            }
            @media (max-width: $size-sm) {
                height: 33%;
            }
            @media (max-width: 620px) {
                height: 37%;
            }
            @media (max-width: $size-xs) {
                height: 40%;
            }
            @media (max-width: 410px) {
                height: 45%;
            }
            @media (max-width: 350px) {
                height: 50%;
            }
        }
    }
    .images-section {
        padding: 50px 0px;
        @include ipad {
            padding: 30px 0px;
        }
        .images {
            text-align: center;
            @media (max-width: $size-md) {
                margin-top: 0px;
            }

            .image {
                display: inline-block;
                width: 90px;
                height: 90px;
                margin-right: 30px;

                @media (max-width: $size-sm) {
                    margin-bottom: 30px;
                    margin-right: 15px;
                    width: 70px;
                    height: 70px;
                }
            }
        }
    }
}

.header_layout {
    background-color: #d83e34;
    .site_logo {
        width: 220px;
        padding: 20px 0px;
        margin: auto;
        img {
            width: 100%;
        }
    }
    .site_nav {
        font-size: 16px;
        font-weight: 700;
        color: #fff;
        padding: 18px 0px;
    }
}

.images {
    text-align: center;
    @media (max-width: $size-md) {
        margin-top: 0px;
    }

    .image {
        display: inline-block;
        width: 90px;
        height: 90px;
        margin-right: 20px;

        @media (max-width: $size-sm) {
            margin-bottom: 30px;
            margin-right: 15px;
            width: 70px;
            height: 70px;
        }
    }
}

.brading_images {
    margin-bottom: 60px;

    img {
        object-fit: contain;
        width: 100%;
    }
    .big {
        height: 72px;
        width: 72px;
    }

    .image {
        display: inline-block;
        margin-right: 20px;
        height: 80px;
        width: 80px;
    }
}

#BuyNowButton {
    cursor: pointer;
}

.custom_button {
    outline: none;
    border: none;
    width: 150px;
    height: 40px;
    text-align: center;
    background: #d71833;
    font-size: 15px;
    border-radius: 3px;
    color: #fff;
    font-family: OpenSans-Semibold;
    text-decoration: none;
}

.payment-gateway {
    width: 100%;
    margin: 0 auto;
    height: 100%;

    .StripeElement {
        background-color: white;
        padding: 8px 12px;
        // border-radius: 4px;
        // border: 1px solid transparent;
        // box-shadow: 0 1px 3px 0 #e6ebf1;
        // -webkit-transition: box-shadow 150ms ease;
        // transition: box-shadow 150ms ease;
    }

    .StripeElement--focus {
        box-shadow: 0 1px 3px 0 #cfd7df;
    }

    .StripeElement--invalid {
        border-color: #fa755a;
    }

    .StripeElement--webkit-autofill {
        background-color: #fefde5 !important;
    }

    .FormGroup {
        margin: 0 15px 10px;
        padding: 0;
        border-style: none;
        background-color: #7795f8;
        will-change: opacity, transform;
        box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #829fff;
        border-radius: 4px;
    }

    .FormRow {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        margin-left: 15px;
        border-top: 1px solid #819efc;
    }

    .StripeElement--webkit-autofill {
        background: transparent !important;
    }

    .StripeElement {
        width: 100%;
        padding: 20px;
        @include ipad {
            padding: 20px 0;
        }
        input, select{
            border-radius: 0;
            border: none;
            box-shadow: none;
            background-color: #747474;
        }
    }

    button {
        display: block;
        font-size: 16px;
        width: 100%;
        height: 40px;
        margin: 20px 0px 0px;
        background-color: #d71833;
        box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #d71833;
        border-radius: 4px;
        color: #fff;
        font-weight: 600;
        cursor: pointer;
        transition: all 100ms ease-in-out;
        will-change: transform, background-color, box-shadow;
        border: none;
    }

    button:active {
        background-color: #d782d9;
        box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #e298d8;
        transform: scale(0.99);
    }
}

.payment-title {
    font-family: PlayfairDisplay-Regular;
    font-size: 40px;
    margin-bottom: 10px;
    width: 100%;
    display: block;
    text-align: center;
}

.loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba($color: #fff, $alpha: 0.7);
    width: 100%;
    height: 100%;
    vertical-align: middle;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 33;
}

.form-block {
    margin: auto;
    padding: 30px 30px;
    max-width: 500px;

    &.form-block {
        min-width: 100%;

        .form-group {
            input,
            select {
                background: #f8f8f8;
                height: 50px;
            }
        }
    }

    @media (max-width: $size-md) {
        padding: 0px;
    }

    .main-title {
        font-family: MyriadPro-Regular;
        text-transform: uppercase;
        font-size: 18px;
        margin-bottom: 20px;
        text-align: center;
        line-height: 25px;
    }

    .form-group {
        margin-bottom: 12px;

        input,
        select {
            border: 1px solid white;
            width: 100%;
            border-radius: 3px;
            font-size: 14px;
            font-family: OpenSans-Semibold;
            height: 47px;
            padding: 0px 10px;
            background: white;

            &.form-select {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e") !important;
                background-repeat: no-repeat !important;
                background-position: right 0.75rem center !important;
                background-size: 16px 12px !important;
            }
            &:focus {
                outline: none;
                box-shadow: none;
            }

            @media (min-width: $size-slg) {
                font-size: 14px;
                height: 40px;
            }

            &::placeholder {
                color: #b3b3b3;
            }
        }
    }

    .button button {
        width: 100%;
        background: $red-color;
        color: white;
        font-size: 12px;
        font-family: Roboto-Bold;
        text-align: center;
        height: 42px;
        border-radius: 18.5px;
        border: 0px;
        margin-top: 20px;
        cursor: pointer;
        padding: 0px;
    }

    .bottom-text {
        color: white;
        text-align: center;
        margin-top: 20px;

        .top {
            font-family: OpenSans-Bold;
            font-size: 14px;
            margin-bottom: 20px;
        }
        .bottom {
            font-family: OpenSans-Semibold;
            font-size: 11px;
            margin-bottom: 30px;
        }
    }
}

.heading-title {
    font-size: 24px;
    line-height: 35px;
    color: $main-fonts-color;
    font-weight: 900;
    font-family: $main-font;
    margin: 5px 0px;
    padding-bottom: 5px;
}

.remove-cart {
    text-align: center;
    img {
        width: 20px;
        cursor: pointer;
    }
}

// Modal styles
.modal {
    .modal-content {
        position: relative;

        .close-modal {
            position: absolute;
            top: 12px;
            right: 12px;
            cursor: pointer;

            img {
                width: 25px;
            }
        }
    }
}

.col-lg-3.col-md-6.col-sm-6.mb-4.mb-lg-0 {
    &:nth-child(3) {
        .product {
            background: #fbe7ea;
            &::after {
                background: $red-color;
            }
        }
    }

    &:nth-child(4) {
        .product {
            background: #ebf6fe;
            &::after {
                background: #3fa9f5;
            }
        }
    }
}

.cart-btn {
    background: #d71833;
    color: white;
    font-size: 13px;
    font-family: OpenSans-Semibold;
    height: 36px;
    display: block;
    text-transform: uppercase;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    text-align: center;
    padding: 8px 18px;
    line-height: 20px;
    cursor: pointer;
    border: none;
    outline: none;
    &:disabled {
        background: #b3b3b3;
        cursor: not-allowed;
        outline: none;
        border: none;
    }
    &.active {
        background: #d71833;
    }
}

.modal-wrapper {
    .modal-dialog {
        max-width: 1000px;
        width: 100%;
        margin: 0 auto;
        @media (max-width: $size-xs) {
            width: 90%;
        }
    }

    .modal-wrapper {
        height: calc(100vh - 10vh);
        overflow-y: auto;
    }

    .modal-product-content {
        .title {
            margin-bottom: 60px;
            text-align: left;
            .main-title {
                font-family: PlayfairDisplay-Regular;
                font-size: 40px;
                margin-bottom: 20px;
            }

            .main-text {
                p {
                    font-family: $main-font;
                    font-size: 15px;
                    line-height: 25px;
                    @include ipad {
                        font-size: 14px;
                    }
                }
                .bold {
                    font-family: OpenSans-Bold;
                }
            }
        }

        .buy-button {
            cursor: pointer;
            bottom: 25px;
            left: 20px;
            color: #d71833;
            font-size: 10px;
            text-transform: uppercase;
            font-family: OpenSans-Semibold;
        }

        .images {
            margin-top: 50px;
            text-align: center;
            .image {
                display: inline-block;
                width: 80px;
                height: 80px;
                margin-right: 20px;
            }
        }

        .product {
            background: #f2f2f2;
            padding: 12px 10px;
            position: relative;
            max-width: 305px;
            margin: auto;
            cursor: pointer;
            border-radius: 5px;
            transition: all ease-in-out 0.3s;
            position: relative;

            @media (max-width: $size-xs) {
                max-width: 100%;
                margin-bottom: 20px;
            }

            .product-title {
                font-size: 15px;
                text-align: center;
            }

            .cart-action-btn {
                position: absolute;
                cursor: pointer;
                bottom: 0;
                right: 0%;
                background: #fff;
                padding: 6px;
                border-top-left-radius: 20px;
                border-bottom-left-radius: 20px;

                img {
                    width: 20px;
                }
            }

            &.active {
                box-shadow: 0px 4px 15px 8px rgba(0, 0, 0, 0.1);
                &::after {
                    background: #d71833;
                }
            }

            &::after {
                content: "";
                height: 4px;
                background: #808080;
                position: absolute;
                width: 100%;
                top: -5px;
                left: 0px;
            }

            .image {
                // height: 100px;
                text-align: center;
                img {
                    height: 100%;
                    -o-object-fit: cover;
                    object-fit: cover;
                }
            }
        }

        .action-btn {
            max-width: 305px;
            width: 100%;
            margin: 0 auto 30px;
            .custom_button {
                width: 100%;
            }
        }

        .product-description {
            .table-striped tbody tr:nth-of-type(odd) {
                background-color: rgba(0, 0, 0, 0.001);
            }

            tr {
                vertical-align: middle;
                td {
                    padding: 12px 10px;
                }
            }
        }
    }
}

td,
th {
    text-wrap: nowrap;
}
.card-table {
    font-size: 14px;
    // @include ipad {
    //     font-size: 12px;
    //     line-height: 16px;
    // }
    tr {
        vertical-align: middle;
    }
    td {
        padding: 15px 10px;

        &.cart-action span {
            padding: 12px 18px;
            font-size: 14px;
            width: 20px;
            height: 10px;
            display: table-cell;
            border: 1px solid #fff;
            color: black;
            background: #f9f9f9;
            cursor: pointer;
            @include ipad {
                padding: 5px 10px;
                font-size: 13px;
                width: 15px;
                height: 7px;
            }
            &:nth-child(2) {
                cursor: default;
            }
        }
    }
}

.total-ammount {
    text-align: end;
    font-size: 15px;
    font-weight: 700;
}

.modal footer {
    position: relative;
    .top {
        background: #000000;
        padding: 30px 0px;

        .logo {
            @media (max-width: $size-md) {
                margin-bottom: 20px;
            }
            img {
                max-width: 225px;
            }
        }

        .text {
            p {
                font-family: MyriadPro-Regular;
                font-size: 18px;
                line-height: 26px;
                color: white;

                a {
                    font-family: MyriadPro-Regular;
                    font-size: 18px;
                    color: white;
                }
            }
        }
    }
    .bottom {
        background: $red-color;
        padding: 20px 0px;

        .copyright {
            font-family: MyriadPro-Regular;
            font-size: 16px;
            color: white;
            @media (max-width: $size-md) {
                text-align: center;
                margin-bottom: 20px;
            }
        }
        .social {
            text-align: right;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 100%;

            @media (max-width: $size-md) {
                justify-content: center;
            }

            ul {
                margin: 0px;
                li {
                    margin-right: 15px;

                    svg {
                        width: 18px;
                        height: 18px;
                        vertical-align: middle;

                        @media (min-width: $size-slg) {
                            height: 18px;
                            width: 18px;
                        }
                    }

                    .fb-icon {
                        height: 18px;
                        width: 10px;

                        @media (min-width: $size-slg) {
                            height: 20px;
                            width: 10px;
                        }
                    }
                    .youtube-icon {
                        width: 22px;
                        height: 20px;

                        @media (min-width: $size-slg) {
                            height: 26px;
                            width: 23px;
                        }
                    }

                    .tw-icon {
                        width: 19px;
                        height: 21px;

                        @media (min-width: $size-slg) {
                            height: 18px;
                            width: 21px;
                        }
                    }
                }
                li:last-child {
                    margin-right: 0px;
                }
            }
        }
    }
}
