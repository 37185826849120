//== Color Variables
$primary: rgb(60, 66, 87);
$primary-2: #ff0000;
$secondary: #e1b73a;
$tertiary: #333333;
$gradient: linear-gradient(90deg, #ee1a15 0%, #deba3b 100%);
$gradient-2: linear-gradient(90deg, #f01a15 0%, #e0bc3b 100%);
$bg-gray: #f1f1f1;
$bg-orange: #edb75d;
$gray: #444444;
$lite-gray: #f9f9f9;
$medium-gray: #808080;
$black: #000;
$medium-black: #2b2a2a;
$white: #fff;
$green: #39a24a;
$red: #e81a15;
$border: #ceced0;
$shadow: 0px 3px 6px #00000029;
$radius: 5px;
$half-white: rgba(255, 255, 255, 0.5);
$quarter-white: rgba(255, 255, 255, 0.25);
$transition: all 0.5s ease-in-out;
$radius-btn: 70px;
$white-op3: rgba(255, 255, 255, 0.3);
