.order-wrapper {
    tbody {
        tr {
            border-color: #f1f1f1;
            td {
                padding: 0.7rem 0.5rem;
            }
            &:last-child {
                border-bottom: transparent;
            }
        }
    }
    .order-header {
        padding-bottom: 5px;
        border-bottom: 1px solid #f5f5f5;
        margin-bottom: 10px;
        h4 {
            font-weight: 600;
            color: $primary;

            span {
                color: #dc3545;
            }
        }
        a {
            &:hover {
                color: $white;
            }
        }
    }

    .widget-item {
        margin-bottom: 35px;
        @include ipad {
            margin-bottom: 25px;
        }
        .widget-title {
            font-size: 15px;
            @include ipad {
                font-size: 14px;
            }
        }
    }
}

.order-info {
    margin-bottom: 10px;

    .badge {
        text-transform: capitalize;
    }
}
