body {
    font-weight: 300;
    margin: 0;
    padding: 0;
    font-size: 15px;
    overflow-x: hidden;
}

// Container Style
.container {
    max-width: 100%;
    margin: 0px -15px;
    max-width: 1002px;
    margin: 0 auto;
}

// Common section padding
.section-padding {
    padding: 100px 0px;
}

// Secondary Background Color
.secondary-bg {
    background: $secondary;
}

// Shop Background
.bg-white {
    background: $white;
}

.bg-gray {
    background: $lite-gray;
}

// White color
.white {
    color: $white;
}

// Black color
.black {
    color: $black;
}

// Gray color
.gray {
    color: $gray;
}

// Invalid feedback
.invalid-feedback {
    color: #dc3545;
}

.no-found {
    color: $secondary;
    font-size: 14px;
}

// Reset css
a {
    outline: none !important;
}

ul {
    margin: 0;
    padding: 0;

    li {
        list-style: none;
    }
}

.btn {
    &:focus {
        outline: 0;
        box-shadow: none;
    }
}

// App style
.App {
    display: flex;
    flex-wrap: wrap;

    > div:nth-child(1) {
        flex-basis: 20%;
    }

    > div:nth-child(2) {
        flex-basis: 50%;
    }

    > div:nth-child(3) {
        flex-basis: 30%;
    }
}

// Heading-area
.heading-area {
    background-color: $secondary;
    color: $white;
    text-align: center;
    font-weight: 500;
    padding: 15px 0px;
    font-size: 16px;
    border-right: 1px solid $border;
}
.modal-headerr {
    position: absolute;
    top: -20px;
    right: -12px;
    width: 35px;
    height: 35px;
    line-height: 38px;
    text-align: center;
    background: #fff;
    border-radius: 100%;
    z-index: 999999;
}
.modal-dialog-scrollable .modal-content {
    overflow: inherit;
}
.modal-title {
    font-family: PlayfairDisplay-Regular;
    font-size: 30px;
    border: none;
    display: block;
    width: 100%;
    text-align: center;
}
.modal-body {
    padding: 35px 40px !important;
    @include ipad {
        padding: 35px 30px !important;
    }
}

.main-content-wrapper {
    font-family: OpenSans-Light;
    font-size: 16px;
    line-height: 28px;
    color: $black;

    h3 {
        color: $black;
        font-family: OpenSans-Semibold;
        margin-bottom: 10px;
        line-height: 30px;
        font-size: 20px;
        @include ipad {
            line-height: 23px;
        }
    }
    ul {
        display: block;
        margin-bottom: 20px;
        li {
            list-style: none;
            display: list-item;
            color: $black;
        }
    }
}

// Custom btn

.custom_button {
    outline: none;
    border: none;
    width: 150px;
    height: 40px;
    text-align: center;
    background: #d71833;
    font-size: 15px;
    border-radius: 3px;
    color: $white;
    font-family: OpenSans-Semibold;
    text-decoration: none;
    transition: $transition;

    &.primary {
        background-color: $bg-orange;
    }

    &.secondary {
        background-color: $secondary;
    }

    &.outline {
        background-color: $white;
        color: $tertiary;
        border: 1px solid $border;
        box-shadow: none;

        &:hover {
            color: $tertiary;
            border: 1px solid $border;
        }
    }

    &.status-btn {
        box-shadow: none;
        color: $black;
        height: 35px;
        min-width: 100px;
        font-size: 12px;

        &:hover {
            color: $black;
        }
    }

    &.lite-green {
        background-color: #d8f2e4;
    }

    &.lite-red {
        background-color: #ecd5d8;
    }

    &.lite-blue {
        background-color: #d9e5f1;
    }

    &.lite-orange {
        background-color: #f2edd9;
    }

    &:focus {
        border: none;
        outline: none;
        box-shadow: $shadow;
    }

    &:hover {
        color: $white;
    }

    &:disabled {
        background-color: $border;
        color: $tertiary;
        box-shadow: none;
        cursor: not-allowed;

        &:hover {
            background-color: $border;
            color: $tertiary;
        }
    }
}

// // Modal styles
// .modal {
//     .heading-title {
//         font-size: 24px;
//         line-height: 35px;
//         color: $tertiary;
//         font-weight: 700;
//         text-align: center;
//     }

//     .modal-content {
//         position: relative;

//         .close-modal {
//             position: absolute;
//             top: 15px;
//             right: 15px;
//             cursor: pointer;

//             img {
//                 width: 28px;
//             }
//         }
//     }
// }

// .modal-wrapper {
//     .modal-dialog {
//         max-width: 580px;
//         margin: 0 auto;
//     }

//     .modal-common-content {
//         .text {
//             color: $tertiary;
//             font-size: 15px;
//             line-height: 24px;
//             font-weight: 500;
//         }
//     }
// }

.action-btn {
    .button {
        max-width: 100px;
        margin: 0px 7px;
    }
}

.close-btn {
    width: 22px;
    height: 22px;
    background: transparent url("../images/cross.png") 0% 0% no-repeat padding-box;
    background-size: contain;
    cursor: pointer;
    opacity: 1;
}

/* Spinner Css */
// .loading-spinner {
//     display: flex;
//     justify-content: center;
//     padding: 100px 0px;
//     position: fixed;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
// }

// Tags list
.tags {
    display: flex;
    flex-wrap: wrap;

    .more {
        color: $primary;
        cursor: pointer;
        margin-bottom: 5px;
        font-weight: 3400;
        font-size: 13px;
    }

    .tag-list {
        color: $secondary;
        font-size: 12px;
        padding: 2px 12px;
        border: 1px solid $secondary;
        border-radius: 4px;
        margin-right: 7px;
        font-weight: 500;
        margin-bottom: 8px;

        &:last-child {
            margin-right: 0px;
        }
    }
}

// Tags
.tags {
    display: flex;
    align-items: center;
    padding: 10px 0px;

    li {
        padding: 3px 15px;
        border: 1px solid $border;
        margin-right: 7px;
        margin-bottom: 5px;
        border-radius: 5px;
        font-size: 13px;
        font-weight: 300;
        position: relative;
        cursor: pointer;

        &.disabled {
            background-color: $border;
            color: $tertiary;
            box-shadow: none;
            cursor: not-allowed;

            &:hover {
                background-color: $border;
                color: $tertiary;
            }
        }

        .remove {
            position: absolute;
            top: 2px;
            right: 2px;
            line-height: 0;
            cursor: pointer;

            img {
                width: 8px;
            }
        }
    }
}

// Error message
.error-message {
    color: #d71833;
    font-weight: 400;
    font-size: 14px;
    width: 100%;
}

// password-visibility
.password-visibility {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 2%;
    cursor: pointer;

    img {
        width: 20px;
        opacity: 0.5;
    }
}

input {
    outline: none;
}

button {
    outline: 0;
    outline-style: none;
    &:focus {
        box-shadow: none !important;
    }
}

.orange_btn {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: $white;
    padding: 10px 55px;
    background: $primary;
    border-radius: $radius-btn;
    text-decoration: none;
    &:hover {
        color: $white;
    }
}

.back_overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: $gradient-2;
    opacity: 0.9;
}

h3,
.h3 {
    width: 100%;
    font-size: 24px;
    line-height: 29px;
    color: $white;
}

// form {
//     width: 100%;
//     fieldset {
//         width: 100%;
//         padding: 0;
//         border: none;
//         margin-bottom: 25px;
//     }
//     .input_label {
//         font-size: 14px;
//         line-height: 17px;
//         color: $white;
//         margin-bottom: 2px;
//     }
//     input {
//         width: 100%;
//         background-color: rgba(226, 227, 228, 0.2);
//         height: 38px;
//         padding: 0 10px;
//         font-size: 14px;
//         line-height: 17px;
//         color: $white;
//         border: none;
//         &::placeholder {
//             color: $white-op3;
//         }
//     }
//     button {
//         margin-bottom: 18px;
//     }
// }

.is-account-exist {
    font-size: 15px;
    margin-bottom: 20px;
    text-align: center;
    line-height: 21px;

    span {
        font-weight: 600;
        color: $primary;
        cursor: pointer;
        margin: 0px 3px;
    }
}

.primary_btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: $primary;
    min-width: 153px;
    height: 35px;
    background: $white;
    border: none;
    border-radius: 70px;
    &.outline {
        border: 1px solid $white;
        color: $white;
        background: transparent;
    }
}

.plant {
    position: absolute;
}

.navbar-toggler-icon {
    width: 25px;
}

.navbar .navbar-toggler .close-icon {
    display: none;
    font-size: 38px;
    line-height: 19px;
}

.navbar-toggler {
    border: none;
}

h2,
.h2 {
    width: 100%;
    font-size: 28px;
    line-height: 34px;
    color: $white;
    margin-bottom: 0;
}

.select__toolbox {
    width: 100%;
    background-color: rgba(226, 227, 228, 0.2);
    border-radius: 4px;
    border: none;
    padding: 10px 15px 10px 10px;
    .select__label {
        height: auto;
        font-size: 14px;
        line-height: 17px;
        color: $white;
    }
    .select__arrow {
        bottom: initial;
        top: 50%;
        transform: translateY(-50%);
        &::before {
            content: "";
            // background: url("../images/arrow_down.svg") top center no-repeat;
            background-size: cover;
            width: 14px;
            height: 10px;
            transform: none;
            margin: 0;
        }
    }
}

.select__dropdown {
    background-color: rgba(226, 227, 228, 0.5);
    border-color: $white;
}

.select__dropdown .select__list .select__item--selected,
.select__dropdown .select__list .select__item:hover {
    background-color: $white;
}

.select__dropdown .select__list .select__item .select__item_text {
    color: $black;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
}

.select__dropdown .select__list {
    margin-bottom: 0;
}

.select__dropdown .select__list .select__item {
    padding-left: 10px;
}

.select__dropdown .select__list .select__item .select__item_icon {
    display: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.select-area {
    max-width: 340px;
    width: 100%;
    margin: 0 auto;

    .select-box {
        background-color: rgba(226, 227, 228, 0.2);
        padding: 10px 15px 10px 10px;
        border-radius: 4px;
        margin-left: auto;
        margin-right: auto;
        outline: none;
        border: none;
        color: $white;
        -moz-appearance: none; /* Firefox */
        -webkit-appearance: none; /* Safari and Chrome */
        appearance: none;
        width: 100%;
        font-weight: 300;
        cursor: pointer;
        option {
            background: #f5f5f5;
            color: $black;
            text-shadow: 0 1px 0 #f5f5f5;
        }
    }
    .select-carret {
        position: absolute;
        top: 50%;
        right: 2%;
        transform: translate(-50%, -50%);
    }
}

.empty-msg {
    font-size: 17px;
    font-weight: 600;
    color: $primary;
}

.list-style-numbered{
    li {
        list-style: decimal !important;
    }
}
